<template>
  <b-form-group
      :label-for="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
      :label="(displayLabel)?capitalize($tc(name)):''"
  >
    <VueFileAgent
        v-show="singleFile == null || resetAfterUploaded"
        ref="vueFileAgent"
        v-model="fileRecords"
        :multiple="multiple"
        :maxFiles="multiple?20:1"
        :deletable="true"
        :linkable="true"
        :accept="acceptedTypesLocal"
        :maxSize="'1GB'"
        :helpText="$t('Choose file or drag & drop here')"
        :type="$t('Invalid file type')"
        :size="$t('Files should not exceed', {size: '1GB'})"
        :theme="listDisplay==true?'list':'default'"
        @beforedelete="onBeforeDelete($event)"
        @delete="fileDeleted($event)"
    >
    </VueFileAgent>

    <card-document
        v-if="singleFile != null && !('id' in singleFile) && !resetAfterUploaded"
        :document="singleFile"
        @click="filesSelected(fileRecords[0])"
        @download="filesSelected(fileRecords[0])"
        @delete="onBeforeDelete(fileRecords[0])"
    />

    <card-document
        v-else-if="singleFile != null && ('id' in singleFile) && !resetAfterUploaded"
        :document="singleFile"
        @click.native="filesSelected(fileRecords[0])"
        @download.native="filesSelected(fileRecords[0])"
        @delete="$emit('delete')"
    />


    <field-input
        v-if="isRequired"
        name="upload"
        :model.sync="fileId"
        :rules="'required'"
        :displayErrorOnly="true"
    />

  </b-form-group>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'

import store from '@/store'
import api from '@/libs/axios'
import fieldInput from '@/components/input/Input'
import CardDocument from '@/components/card/Document'

export default {
  components: {
    fieldInput,
    CardDocument
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    acceptedTypes: {
      type: Array,
      default: () => ['all']
    },
    documentType: {
      type: String,
      default: ''
    },
    singleFile: {
      type: Object,
      default: () => {}
    },
    listDisplay: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    resetAfterUploaded: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    listIndex: {
      type: Number,
      default: -1
    },
    displayLabel: {
      type: Boolean,
      default: false
    },
  },
  setup (props, { emit }) {
    // console.log(props.singleFile)
    // console.log(props.acceptedTypes)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const fileRecords = ref([])
    const uploadHeaders = ref({
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    })
    const apiURL = ref(api.defaults.baseURL + 'documents')

    const vueFileAgent = ref(null)

    const fileId = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const acceptedTypesLocal = computed(() => {
      let output = []
      props.acceptedTypes.forEach(acceptedType => {
        if (acceptedType == 'all') {
          return '*'
        } else if (acceptedType == 'image') {
          output.push('image/*')
        } else if (acceptedType == 'pdf') {
          output.push('application/pdf')
        } else {
          output.push('.' + acceptedType)
        }
      })

      return output.join(',')
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(fileRecords, () => {
      // console.log(fileRecords.value)
      if (fileRecords.value.length && !('id' in fileRecords.value[0])) {
        vueFileAgent.value.upload(
            apiURL.value,
            uploadHeaders.value,
            [fileRecords.value.at(-1)],
            (fileData) => {
              // console.log(fileData.file)
              let formData = new FormData()
              formData.append('type', props.documentType)
              formData.append('file', fileData.file)
              return formData
            }
        )
            .then(response => {
              emit('update:singleFile', response[0].data)

              if (props.resetAfterUploaded) {
                // console.log('in')
                // fileRecords.value = JSON.parse(JSON.stringify([]))
                fileRecords.value.splice(0, 1)
                fileId.value = null
              } else {
                fileId.value = response[0].data.id
              }
            })
      } else {
        // Delete file from edited source on put
        // emit('update:singleFile', null)
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const filesSelected = (fileRecordsNewlySelected) => {
      console.log(fileRecordsNewlySelected)
      let link = document.createElement('a')
      link.setAttribute('target', '_blank')
      // link.href = fileRecordsNewlySelected.upload.data.url
      link.href = fileRecordsNewlySelected.url
      link.click()
    }

    const onBeforeDelete = (fileRecord) => {
      console.log('0')
      // console.log('onBeforeDelete')
      vueFileAgent.value.deleteFileRecord(fileRecord)
    }

    const fileDeleted = (fileRecord) => {
      // console.log('fileDeleted')
      let i = fileRecords.value.indexOf(fileRecord)
      if (i !== -1) {
        fileRecords.value.splice(i, 1)
        fileId.value = null
        emit('update:singleFile', null)

        // } else {
        // this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        // this.deleteUploadedFile(fileRecord)
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    onMounted(() => {
      if (props.singleFile != null) {
        fileRecords.value.push({
          name: props.singleFile.name + '.' + props.singleFile.extension,
          ext: props.singleFile.extension,
          size: props.singleFile.size,
          type: props.singleFile.mimeType,
          url: props.singleFile.url,
          id: props.singleFile.id
        })

        fileId.value = props.singleFile.id

      }
    })

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      fileRecords,
      uploadHeaders,
      apiURL,
      vueFileAgent,
      fileId,

      // Computed
      acceptedTypesLocal,

      // Methods
      filesSelected,
      onBeforeDelete,
      fileDeleted,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    // fileRecords: function (val) {
    //   console.log(val)
    //
    //   this.$refs.vueFileAgent.upload(
    //       this.apiURL,
    //       this.uploadHeaders,
    //       this.fileRecords,
    //       (fileData) => {
    //         let formData = new FormData()
    //         formData.append('type', 'order')
    //         formData.append('file', fileData.file)
    //         return formData
    //       }
    //   )
    // }
    // singleFile: function (val) {
    //   console.log(val)
    // }
  },
  methods: {},
  mounted () {
    // console.log(this.singleFile)
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>